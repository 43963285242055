.select-table {

  width: 100%;

  &-title {
    color: $color-blue;
    padding-bottom: 10px;
  }

  tbody tr {
    &.extra-hidden {
      display: none;
    }
  }

  thead tr th,
  tbody tr td {
    line-height: 1.3em;
    padding: 8px;
  }

  thead tr th,
  tbody tr:first-child td {
    border-top: solid 1px $color-gray;
  }
  thead tr td {
    border-bottom: solid 1px $color-gray;
  }
  tbody tr td {
    border-bottom: solid 1px $color-light-gray;
  }
  tbody tr:nth-child(odd) {
    background-color: lighten($color-light-gray,8.5%);
  }

  td, th {
    &:nth-child(1) {
      width: 60px;
    }
    &:nth-child(2) {
      width: auto;
    }
    &:nth-child(3) {
      width: 120px;

      @media (max-width:500px) {
        width: 85px;
        text-align: center;
      }
    }
  }

  td {
    @include links( $color-blue, $color-green, none, none );

    .fa-times-circle {
      cursor: pointer;
    }

    a {
      cursor: pointer;
    }
  }

  .cart-add {
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $color-green;
    }

    span {
      display: inline-block;
      margin-left: 8px;
      @include rem-size(0.85);
    }
  }

  .cart-remove {
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $color-red;
    }

    span {
      display: inline-block;
      margin-left: 8px;
      @include rem-size(0.85);
    }
  }

  @media (max-width:500px) {
   .cart-add, .cart-remove {
     text-align: center;

     i {
       display: block;
       text-align: center;
       margin: 3px auto 0;
     }
   }
  }
}

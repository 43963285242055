.acco-browser {
  overflow: visible;
  display: block;

  &-nav {
    float: left;
    width: 300px;
    margin-right: 20px;
  }

  &-body {
    float: left;
    width: calc(100% - 320px);
  }

  .browserNav-category {
    display: block;
    color: $color-blue !important;
    font-weight: bold;
    font-size: 18px;
    font-family: 'atten-round-new', arial, sans-serif;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: solid 1px $color-light-gray;
    cursor: pointer;

    &:hover {
      color: $color-green !important;
    }

  }

  .browserNav-block {
    margin-bottom: 24px;
    display: none;

    &.open {
      display: block;
    }
  }

  .browserNav-subtopic {
    cursor: pointer;
    color: $colorText;
    padding: 6px;

    &:hover {
      color: $color-green;
    }

    &.active {
      background-color: #DAF094;
      font-weight: bold;
    }
  }

  &-select {
    display: none;
    margin-bottom: 24px;
  }

  @media (max-width: 775px) {
    .acco-browser {
      &-select {
        display: block;
      }
      &-nav {
        display: none;
      }
      &-body {
        width: 100%;
      }
    }
  }

}

.acco-builder {
  display: flex;

  @media (max-width: 600px) {
    width: 100%;
    display: block;
  }

  &-nav {
    width: 275px;

    img {
      display: block;
      margin: 50px auto 0;

      @media (max-width: 600px) {
        display: none;
      }
    }
    
    @media (max-width: 800px) {
      width: 220px;
      zoom: 75%;
    }
    @media (max-width: 600px) {
      width: 100%;
      zoom: 100%;
    }
  }

  &-body {
    width: calc( 100% - 275px );
    border-left: solid 1px $color-light-gray;
    margin-left: 30px;
    padding-left: 30px;

    @media (max-width: 800px) {
      width: calc( 100% - 220px );
      zoom: 80%;
    }
    @media (max-width: 600px) {
      width: 100%;
      zoom: 100%;
      border-left: 0;
      margin-left: 0;
      padding-left: 0;
    }
    @media (max-width: 500px) {
      zoom: 90%;
    }
    @media (max-width: 400px) {
      zoom: 100%;
    }
  }

  &-navlist {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 0;

    li {
      margin-bottom: 20px;

      @media (max-width: 600px) {
        margin-bottom: 10px;
      }
    }
    .on {
      opacity: 1;
    }
    .off {
      opacity: 0.35;
    }
    i {
      background-color: $color-blue;
      width: 30px;
      height: 30px;
      border-radius: 16px;
      text-align: center;
      line-height: 30px;
      color: $white;
      display: inline-block;
      margin-right: 12px;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
    }
    a, div {
      display: block;
      color: $black;
      line-height: 30px;
      font-weight: bold;
      font-size: 18px;
    }
    a:hover {
      text-decoration: none;
    }

    @media (max-width: 600px) {
      display: block;
      width: 215px;
      margin: 0 auto;
      position: relative;
      top: -15px;
    }
  }

  &-flowbtn {
    text-align: center;
    width: calc( 100% - 250px );
    margin-left: 250px;

    @media (max-width: 800px) {
      width: calc( 100% - 220px );
      margin-left: 220px;
    }
    @media (max-width: 600px) {
      width: 100%;
      margin-left: 0;
    }
    @media (max-width: 500px) {
      zoom: 90%;
    }
    @media (max-width: 400px) {
      zoom: 100%;
    }
  }

}

.acco-priority {
  display: block;
  overflow: auto;
  padding: 8px 10px 0;

  &:nth-child(odd) {
    background-color: $white;
  }
  &:nth-child(even) {
    background-color: lighten($color-light-gray,8.5%);
  }

  &-setting {
    float: left;
    width: 110px;
    text-align: center;

    input {
      margin-right: 6px;
    }
  }

  &-trash {
    float: left;
    width: 40px;
    color: $color-red;
    cursor: pointer;
  }

  &-name {
    float: left;
    width: calc(100% - 370px);
  }

  @media (max-width: 500px) {
    .acco-priority-trash {
      width: 26px;
    }
    .acco-priority-name {
      width: calc(100% - 26px);
    }
    .acco-priority-setting {
      margin-top: 6px;
      display: inline-block;
      width: initial;
      margin-left: 15px;
    }
    .acco-priority-setting.high {
        margin-left: 40px;
    }
  }
}

.emailbox {
  width: 100%;
  height: 185px;
  border: solid 1px $color-light-gray;
  padding: 8px;
  border-radius: 5px;

  option {
    padding: 4px 6px;

    &[selected] {
      background-color: $color-green;
    }
  }
}

.pdfLoading {
  color: #FFF;
  margin-left: 5px;
  zoom: 130%;

  &.hidden {
    display:none;
  }
}

button img.gearsLoad {
  width: 23px;
  height: 23px;
  margin-left: 11px;
  float: right;
}


.pdf-iframe {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999999;
    display: table;

  &-body {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  &-content {
    background-color: $white;
    max-width: 1200px;
    max-height: 1200px;
    height: 80vh;
    margin: auto;
    border-radius: 10px;
    padding: 0px;
    text-align: center;
    position: relative;
    @include shadow( 10px, 40px, 0.5);

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  &-close {
    position: absolute;
    top: -8px;
    right: -10px;
    font-size: 36px;
    border-radius: 18px;
    overflow: visible;
    cursor: pointer;
    width: 35px;
    height: 32px;
    @include shadow(3px, 6px, 0.6);

    .fa-circle {
      color: #FFFFFF;
      position: absolute;
      font-size: 34px;
      top: 1px;
      right: 1px;
    }
    .fa-times-circle {
      color: $color-red;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }

  &-save {
    position: absolute;
    width: 100%;
    display: block;
    bottom: -13px;
    font-size: 36px;
    overflow: visible;
    cursor: pointer;
    height: 32px;
    text-align: center;

    button {
      min-width: 200px;
      border-radius: 50px;
      font-weight: bold;
      @include shadow(3px, 6px, 0.6);
      @include rem-size(1.2);
    }
  }
}

.acco-register {
  margin-top: 130px;
  margin-bottom: 200px;

  h1.plan-select {
    color: $color-blue;
    margin-bottom: 28px;
    text-align: center;
  }

  .acco-plans {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .acco-plan {
    display: flex;
    flex-direction: column;

    min-height: 400px;
    width: 30%;
    border-radius: 20px;
    border: solid 2px #F3F3F3;
    overflow: hidden;
    transform: scale(1);
    margin-bottom: 40px;
    @include shadow();
    @include transition(all 0.3s ease);

    @media (max-width: 850px) {
      width: 45%;
    }
    @media (max-width: 625px) {
      width: 100%;
    }

    div {
      flex: 1;
    }

    &:hover {
      border: solid 2px $color-green;
      transform: scale(1.05);

      h4 {
        background-color: $color-green;
        font-weight: 700;
      }

      p {
        background-color: #EEE;
      }
    }

    h4 {
      min-height: 100px;
      background-color: $color-blue;
      color: #FFF;
      text-align: center;
      padding: 20px 14px;
      @include transition(all 0.3s ease);
      text-transform: uppercase;
      margin: 0;
      @include rem-size(1.6);
    }

    p {
      margin: 0;
      padding: 8px;
      color: $colorText;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      background-color: #EEE;
      @include transition(all 0.3s ease);
      @include rem-size(0.95);
    }

    a, button {
      display: block;
      margin: 12px 15% 24px;
    }

    ul {
      margin: 15px 26px 24px;

      li {
        color: $color-green;
        font-weight: 500;
        line-height: 1.1 !important;
        margin-bottom: 8px;
        @include rem-size(1.25);

        span {
          color: $colorText;
          @include rem-size(0.9);
        }
      }
    }

    .access-code {
      text-align: center;
      margin: 15px 26px 24px;

      i {
        font-weight: bold;
        font-style: normal;
        color: $color-green;
        text-transform: uppercase;
        display: block;
        text-align: center;
        margin-top: 24px;
      }

      input.form-control {
        @include rem-size(1.5);
        text-align: center;
      }
    }
  }

  .have-an-account {
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
  }

  .form-set {
    margin-bottom: 40px;

    &-header {
      margin-bottom: 16px;
      color: $color-blue;
    }
  }

  .form-group-half {
    float: left;
    width: 48%;

    &:first-child {
      margin-right: 4%;
    }
  }
}

.auth-form .form-group {
  overflow: auto;
  clear: both;

  label {
    font-weight: 600;
  }
}

textarea.tc {
  display: block;
  margin: auto;
  height: 200px !important;
  max-width: 600px;
}
textarea.tc2 {
  display: block;
  margin: auto;
  height: 200px !important;
}


.form-control.est-req {
  font-weight: bold;
  text-align: center;
  padding: 0.75em;
  @include rem-size(2.5);
}


.hidden {
  display: none;
}
.estimateSent {
  display: none;
  &.sent {
    display: block;
  }
}
.estimateSendError {
  display: none;
  &.showError {
    display: block;
  }
}


.acco-terms {
  margin-top: 130px;
  margin-bottom: 200px;

  h3 {
    margin-top: 2em;
  }
}

.acco-profile {
  margin-top: 0 !important;
  margin-bottom: 200px;
}

.complete-label {
  @include rem-size(1.2);
  font-weight: bold !important;
}
.complete-value {
  @include rem-size(1.2);
  font-weight: bold !important;
  color: $color-blue;
}
